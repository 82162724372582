@media print, screen and (min-width: 680px) {
  #bg_wrap {
    padding: 50px 0;
  }
}

@media screen and (max-width: 679px) {
  #bg_wrap {
    padding: 0;
  }
}

@media print, screen and (min-width: 680px) {
  .inner {
    width: 700px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 679px) {
  .inner {
    padding: 0 6%;
  }
}

header.ttl_nophoto h1 {
  margin-bottom: 0;
}

.l-contact-present {
  padding: 20px 30px 30px 30px;
  background: #D9D9D9;
}

.l-contact-present .title {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 10px;
}

.l-contact-present .txt {
  letter-spacing: 2px;
}

.contact-comment {
  padding: 50px 0;
  text-align: center;
}

.contact-comment a {
  text-decoration: underline;
}

.l-contact-form .required {
  color: #fff;
  background: #F6AA00;
  padding: 1px 6px 2px 10px;
  margin-right: 10px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 5px;
  position: relative;
  top: -2px;
}

.l-contact-form .date {
  border-bottom: 0;
}

.l-contact-form .date dt {
  vertical-align: middle;
}

.l-contact-form .date dt:first-child + dd {
  margin-bottom: 15px;
}

.l-contact-form .date select {
  border: 0;
  border-bottom: 1px solid #000;
  font-size: 16px;
}

@media print, screen and (min-width: 680px) {
  .l-contact-form .date select {
    padding: 7px 30px 7px 0;
  }
}

@media screen and (max-width: 679px) {
  .l-contact-form .date select {
    padding: 7px 18px 7px 0;
  }
}

.l-contact-form dl {
  padding-top: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}

.l-contact-form dt {
  font-weight: bold;
  margin-bottom: 30px;
}

.l-contact-form input[type="text"] {
  border: 0;
  outline: none;
  font-size: 16px;
}

@media print, screen and (min-width: 680px) {
  .l-contact-form input[type="text"] {
    width: 693px;
  }
}

@media screen and (max-width: 679px) {
  .l-contact-form input[type="text"] {
    width: 100%;
  }
}

.l-contact-form textarea {
  height: 170px;
  font-size: 16px;
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0;
}

@media print, screen and (min-width: 680px) {
  .l-contact-form textarea {
    width: 693px;
  }
}

@media screen and (max-width: 679px) {
  .l-contact-form textarea {
    width: 95%;
  }
}

.l-contact-form .contact-cond ul:after {
  content: "";
  display: table;
  clear: both;
}

.l-contact-form .contact-cond li {
  width: 170px;
  float: left;
  margin-top: 7px;
}

.l-contact-form .contact-land li {
  margin-top: 7px;
}

.l-contact-form .contact-land li:first-child {
  margin-top: 0;
}

.l-contact-form .cell-wrapper {
  padding-top: 40px;
}

.l-contact-form .cell-wrapper .cell-title {
  font-weight: bold;
  margin-bottom: 40px;
}

@media print, screen and (min-width: 680px) {
  .l-contact-form .cell-wrapper .cell-container {
    display: flex;
    justify-content: space-between;
  }
}

.l-contact-form .cell-wrapper input[type="text"] {
  width: 300px;
}

.l-contact-form .cell-wrapper .cell {
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}

@media print, screen and (min-width: 680px) {
  .l-contact-form .cell-wrapper .cell {
    width: 325px;
  }
}

@media screen and (max-width: 679px) {
  .l-contact-form .cell-wrapper .cell {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 679px) {
  .l-contact-form .cell-wrapper .cell:last-child {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 680px) {
  .l-form-btnarea {
    display: flex;
  }
}

input[type="submit"], button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.select-wrap {
  position: relative;
  cursor: pointer;
  padding-right: 0;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.select-wrap:first-child {
  margin-left: 0;
}

.select-wrap select {
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background: #fff;
}

.select-wrap .carat {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -2px;
  border: 4px solid transparent;
  border-top: 6px solid #777;
}

.mw_wp_form_confirm .carat {
  display: none !important;
}

.returnbtn {
  background: #fff;
  border: 1px solid #000;
  padding: 15px 0;
  margin: 0 auto;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  transition: 0.3s;
}

@media print, screen and (min-width: 680px) {
  .returnbtn {
    width: 398px;
    font-size: 16px;
    letter-spacing: 1px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 679px) {
  .returnbtn {
    width: 70%;
  }
}

.returnbtn:hover {
  background-color: #F6AA00;
}

.submitbtn {
  background: #000;
  border: 1px solid #000;
  color: #fff;
  padding: 15px 0;
  margin: 0 auto;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  transition: 0.3s;
}

@media print, screen and (min-width: 680px) {
  .submitbtn {
    width: 398px;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 679px) {
  .submitbtn {
    width: 70%;
  }
}

.submitbtn:hover {
  background-color: #F6AA00;
}

.finish-text {
  text-align: center;
  padding-top: 80px;
}

@media print, screen and (min-width: 680px) {
  .finish-text {
    line-height: 2em;
  }
}

@media screen and (max-width: 679px) {
  .finish-text {
    text-align: left;
    line-height: 2em;
  }
}

.finish-text .title {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 679px) {
  .finish-text .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 370px) {
  .finish-text .title {
    font-size: 20px;
  }
}
