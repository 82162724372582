@charset "utf-8";

@import "setting";


#bg_wrap{
	@include mq(pc){
	padding:50px 0;
	}
	@include mq(sp){
		padding: 0;
	}
}

.inner{
	@include mq(pc){
	width:700px;
	margin:0 auto;
	}
	@include mq(sp){
		padding:0 6%;
	}
}

header.ttl_nophoto{
	h1{
		margin-bottom: 0;
	}
}

.l-contact-present{
	padding:20px 30px 30px 30px;
	background: #D9D9D9;
	.title{
		font-size:45px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.txt{
		letter-spacing:2px;
	}
}

.contact-comment{
	padding:50px 0;
	text-align:center;
	a{
		text-decoration:underline;
	}
}

.l-contact-form{
	.required{
		color:#fff;
		background: #F6AA00;
		padding:1px 6px 2px 10px;
		margin-right: 10px;
		display: inline-block;
		font-size:12px;
		font-weight: normal;
		letter-spacing:5px;
		position: relative;
    	top: -2px;
	}
	.date{
		dt{
			vertical-align:middle;
			&:first-child{
				& + dd{
				margin-bottom: 15px;
				}
			}
		}
		select{
			border:0;
			border-bottom: 1px solid #000;
			font-size:16px;
			@include mq(pc){
			    padding: 7px 30px 7px 0;
			}
			@include mq(sp){
			    padding: 7px 18px 7px 0;
			}
		}
			border-bottom: 0;
	}
	dl{
		padding-top: 40px;
		padding-bottom: 15px;
		border-bottom: 1px solid #000;
	}
	dt{
		font-weight: bold;
		margin-bottom: 30px;
	}
	input[type="text"]{
		border:0;
		@include mq(pc){
			width:693px;
		}
		@include mq(sp){
			width:100%;
		}
		outline:none;
		font-size:16px;
	}
	textarea{
		@include mq(pc){
			width:693px;
		}
		@include mq(sp){
			width:95%;
		}
		height:170px;
		font-size:16px;
		border:1px solid #ccc;
		box-shadow:none;
		border-radius:0;

	}
	.contact-cond{
		ul:after{
			content:"";
			display:table;
			clear:both;
		}
		li{
			width:170px;
			float: left;
			margin-top: 7px;
		}
	}
	.contact-land{
		li{
			margin-top: 7px;
			&:first-child{
				margin-top: 0;
			}
		}
	}
	.cell-wrapper{
		padding-top: 40px;
	    .cell-title{
	    	font-weight: bold;
	    	margin-bottom: 40px;
	    }
		.cell-container{
			@include mq(pc){
			display:flex;
			justify-content:space-between;
			}
		}
		input[type="text"]{
			width:300px;
		}
		.cell{
			@include mq(pc){
				width:325px;
			}
			@include mq(sp){
				margin-bottom: 40px;
			}
			&:last-child{
				@include mq(sp){
					margin-bottom: 0;
				}
			}
		    padding-bottom: 15px;
		    border-bottom: 1px solid #000;
		}
	}
}

.l-form-btnarea{
	@include mq(pc){
		display:flex;
	}
}

input[type="submit"],button{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius:0;
}

.select-wrap{
	position: relative;
	cursor: pointer;
	padding-right: 0;
	display:inline-block;
	&:first-child{
			margin-left: 0;
	}
		margin-left: 10px;
		margin-right: 10px;
	select{
	width: 100%;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius:0;
	background: #fff;
	}
	.carat {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -2px;
	border: 4px solid transparent;
	border-top: 6px solid #777;
	}
}

.mw_wp_form_confirm{
	.carat{
		display:none !important;
	}
}

.returnbtn{
	background: #fff;
    border: 1px solid #000;
    @include mq(pc){
	    width:398px;
	    font-size:16px;
	    letter-spacing:1px;
	    margin-right: 20px;
	}
	@include mq(sp){
		width:70%;
	}
    padding: 15px 0;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    cursor:pointer;
    transition:0.3s;
    &:hover{
    	background-color: #F6AA00;
    }
}

.submitbtn{
	background: #000;
    border: 1px solid #000;
    color: #fff;
    @include mq(pc){
	    width:398px;
	    font-size:16px;
	    letter-spacing:1px;
	}
	@include mq(sp){
		width:70%;
	}
    padding: 15px 0;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    cursor:pointer;
    transition:0.3s;
    &:hover{
    	background-color: #F6AA00;
    }
}

.finish-text{
	text-align:center;
	padding-top: 80px;
	@include mq(pc){
		line-height:2em;
	}
	@include mq(sp){
		text-align:left;
		line-height:2em;
	}
	.title{
	    font-size: 28px;
	    line-height: 30px;
	    font-weight: bold;
		margin-bottom: 50px;
		text-align:center;
		@include mq(sp){
			font-size:24px;
		}
		@include mq(iphone5){
			font-size:20px;
		}
	}
}