@charset "utf-8";


$spwidth:90.5%;

@mixin clearfix{
	&:after{
	content:"";
	display:table;
	clear:both;
	}
}

$breakpoints: (
  'sp': 'screen and (max-width: 679px)',
  'pc': 'print, screen and (min-width: 680px)',
  'iphone5':'screen and (max-width: 370px)',
);

@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin inner{
	width:700px;
	margin:0 auto;
	@include mq(sp){
		width:$spwidth;
	}
}

@mixin inner02{
	width:1000px;
	margin:0 auto;
	@include mq(sp){
		width:$spwidth;
	}
}


@mixin garamond{
	font-size:34px;
	font-family: 'EB Garamond', serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    @include mq(sp){
    	font-size:20px;
    }
}

@mixin mincho{
	font-family: 游明朝, YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", HG明朝E, "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin gothic{
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo UI", Meiryo, sans-serif;
}

@mixin pos-center{
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	margin:auto;
}

@mixin section-title{
		text-align:center;
		font-weight: normal;
		font-size:12px;
		margin-bottom: 33px;
		span{
			@include mincho;
			font-size:34px;
			font-weight: normal;
			display: block;
			letter-spacing: 0.15em;
			margin-bottom: 11px;
			@include mq(sp){
				font-size:22px;
			}
		}
}

@mixin section-title02{
	font-weight: bold;
		text-align:center;
		margin-bottom: 33px;
			font-size:26px;
			display: block;
			letter-spacing: 0.15em;
			margin-bottom: 67px;
			@include mq(sp){
				font-size:22px;
			}
}